<template>
    <div v-if="ready">
        <template v-if="ORDER.status == 'draft' || this.$route.name == 'order_edit'">
            <FormCompetition v-if="ORDER.type == 'competition' && STAGE && ORDER" :postData="STAGE" :orderdata="ORDER" />
            <FormCampView v-if="(ORDER.type == 'camp' || ORDER.type == 'master_class') && EVENT && ORDER" :postData="EVENT" :orderdata="ORDER" />
        </template>
        <template v-else>
            <RequestCompetition v-if="ORDER.type == 'competition' && STAGE && ORDER" :postData="STAGE" :orderdata="ORDER" />
            <RequestCampView v-if="(ORDER.type == 'camp' || ORDER.type == 'master_class') && EVENT && ORDER" :postData="EVENT" :orderdata="ORDER" />
            <PhotobankOrder v-if="ORDER.type == 'photobank'" :orderdata="ORDER" />
        </template>
    </div>
</template>

<script>
import PhotobankOrder from '../photobank/PhotobankOrder'
import RequestCampView from '../RequestCampView'
import RequestCompetition from '../RequestCompetition'
import FormCampView from '../forms/FormCampView.vue'
import FormCompetition from '../forms/FormCompetition.vue'
import moment from 'moment-timezone'
import {
    mapGetters, mapActions
} from "vuex";
import setMeta from "@/views/seoMeta"
export default {
    data(){
        return {
            ready: false,
            type: null,
        }
    },
    computed: {
        ...mapGetters({
            EVENT: "events/getEventPost",
            STAGE: "events/getStagePost",
            ORDER: "order/getOrderData",
        }),
    },
    async mounted() {
        setMeta(
            `Заказ ${this.$route.params.id} | Swim4you.ru`, 
            null, 
            null,
            false
        )
        await this.routeOrder()
        await this.fetchPostData()
        this.checkExpirationEvent()
    },
    methods: {
        ...mapActions({
            fetchOrder: "order/fetchOrderData",
            fetchEvent: "events/fetchEvent",
            fetchStage: "events/fetchStage",
        }),
        async routeOrder(){
            if(!this.$route.params.id){
                this.$swal({
                    title: 'Неверная ссылка',
                    text: 'Пожалуйста проверьте правильность написания ссылки или обратитесь к администратору сайта',
                    icon: 'error',
                }).then((value) => {
                    this.$router.back()
                });
                return false
            }
            await this.fetchOrder(this.$route.params.id)
        },
        async fetchPostData(){
            if(this.ORDER.type == 'camp' || this.ORDER.type == 'master_class'){
                await this.fetchEvent(this.ORDER.event_id)
                this.ready = true
            } 
            else if(this.ORDER.type == 'competition') {
                await this.fetchStage(this.ORDER.event_stage_id)
                this.ready = true
            } 
            else if(this.ORDER.type == 'photobank') {
                this.ready = true
                return
            }
            else {
                this.$router.push({name: 'not_found'})
            }
        },
        checkExpirationEvent(){
            if(this.ORDER.type == 'competition' && this.ORDER.status == 'draft' && this.STAGE){
                if(moment(this.STAGE.current_time, 'DD-MM-YYYY HH:mm') < moment(this.STAGE.start_registration_date, 'DD-MM-YYYY HH:mm')){
                    this.$router.go(-1)
                    this.$swal({
                        title: 'Регистрация на событие еще не началась',
                        text: 'Пожалуйста, зарегистрируйтесь позже',
                        icon: 'error',
                    });
                }

                if(moment(this.STAGE.current_time, 'DD-MM-YYYY HH:mm') > moment(this.STAGE.end_registration_date, 'DD-MM-YYYY HH:mm')){
                    this.$router.go(-1)
                    this.$swal({
                        title: 'Регистрация на событие уже окончена',
                        icon: 'error',
                    });
                }
            }
        }
    },
    components: {
    PhotobankOrder,
    RequestCampView,
    RequestCompetition, FormCompetition, FormCampView },
}
</script>

<style>

</style>