<template>
	<div
		v-if="isAllowed"
		class="athlete_checkboxes__distancies__item d-flex justify-content-between py-3">
		<span
			class="fw-bold"
			:class="checked ? 'text-success' : ''"
			>{{ distance.name }}</span
		>
		<input
            :checked="checked"
			@change="$emit('checkDistance', $event)"
			type="checkbox"
			class="form-check-input ml-2" />
	</div>
</template>

<script>
export default {
    props: {
        service: {
            type: Object,
            default: {}
        },
        distance: {
            type: Object,
            required: true
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isAllowed(val){
            if(!val) this.$emit('uncheckDistance')
        }
    },
    computed: {
        isAllowed(){
            if(!this.service.for_distances || !this.service.for_distances.length) return true
            return this.service.for_distances.includes(this.distance.distance.toString())
        },
    },
    emits: ['checkDistance', 'uncheckDistance']
}
</script>

<style></style>
