<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2">Спортсмены</h2>
    </div>
    <div class="data_table">
        <table class="table table-hover table-responsive">
            <thead>
                <tr>
                    <th scope="col fw-bold">ФИО</th>
                    <th scope="col fw-bold">Дата рождения</th>
                    <th scope="col fw-bold">Разряд</th>
                    <th v-if="distances" scope="col fw-bold">Дистанции и заявочное время</th>
                    <th v-if="documents" scope="col fw-bold">Онлайн-допуск</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in items" :key="i">
                    <td class="small align-middle">
                        <div v-if="item?.athlete" class="table_name_item d-flex align-items-center">
                            <Avatar :src="item.athlete?.avatar" :name="item.athlete?.name" :class="'me-2'" />
                            <span class="fw-bold lh-1">{{item?.athlete?.name}}</span>
                        </div>
                    </td>
                    <td class="small align-middle">{{item?.athlete?.birthdate}}</td>
                    <td class="small align-middle">
                        <span v-if="item.athlete?.category" class="badge bg-warning">{{item.athlete.category}}</span>
                    </td>
                    <td v-if="distances" class="small align-middle">
                        <p v-for="(dist, n) in item.distances" :key="n" class="mb-0">
                            {{dist.name}} - <b>{{correctEntrytime(dist.entrytime)}}</b>
                        </p>
                    </td>
                    <td v-if="documents" class="small align-middle text-end">
                        <vue-feather v-if="item.online_request" type="check-square" class="text-success"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import Avatar from '@/components/UI/Avatar.vue'
export default {
    props:{
        items:{
            type: Array,
            default: []
        },
        distances:{
            type: Boolean,
            default: false
        },
        documents:{
            type: Boolean,
            default: false
        },
        short_swimtime: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            modal_visible: false
        }
    },
    methods: {
        correctEntrytime(entrytime){
            if(!entrytime) return null;
            if(this.short_swimtime){
                return entrytime.slice(3)
            }
            return entrytime
        }
    },
    components: { Avatar },
}
</script>

<style scoped>
    thead th{
        font-size: 14px;
    }
</style>
