import axios from "axios"
import api from '@/store/api'

export const shopStateModule = {
	state: () => ({
		cart_items: [
            /* {
                id: 1,
                name: 'Название товара 1',
                variation: {
                    key: 'size',
                    value: 'XL'
                },
                thumbnail: '/assets/testdata/shop_product_1.jpg',
                price: 2600,
                quantity: 1
            },
            {
                id: 2,
                name: 'Название товара 2',
                variation: null,
                thumbnail: '/assets/testdata/shop_product_2.jpg',
                price: 2600,
                quantity: 1
            }, */
        ],
	}),
	getters: {
		CART(state) {
			return state.cart_items
		},
        CARTSUMM(state) {
            if(!state.cart_items || !state.cart_items.length) return 0
            let summ = 0
            state.cart_items.forEach(item => {
                summ += item.price * item.quantity
            })
            return summ
        },
        CARTCOUNT(state) {
            if(!state.cart_items || !state.cart_items.length) return 0
            let count = 0
            state.cart_items.forEach(item => {
                count += item.quantity
            })
            return count
        }
	},
	mutations: {
        setCart(state) {
			state.cart_items = localStorage.getItem('shop_cart')
				? JSON.parse(localStorage.getItem('shop_cart'))
				: state.cart_items
		},
        addToCart(state, item) {
			let exist = state.cart_items.find(cart_item => cart_item.id == item.id)
			if(exist) {
				exist.quantity += item.quantity ? item.quantity : 1
				return 
			}
            let product_data = {
				id: item.id,
				name: item.name,
				thumbnail: item.thumbnail,
				price: item.price ? item.price : item.min_price,
				quantity: item.quantity ? item.quantity : 1,
			}

			if(item.variation){
				product_data.variation = item.variation
			}
			
			state.cart_items.push(product_data)
		},
		removeFromCart(state, id) {
			state.cart_items = state.cart_items.filter((el) => el.id != id)
		},
		changeQuantity(state, {id, val}) {
			let cart_item = state.cart_items.find(item => item.id === id)
			cart_item.quantity = val
			//state.cart_items = state.cart_items.filter((el) => el.id != id)
		},
		clearCart(state) {
			state.cart_items = []
			localStorage.removeItem('shop_cart')
		},
		/* setPhotos(state, response) {
			state.photobank = response.items
			state.total = response.total
			state.search = response.search
		},
		setPayedPhotos(state, response) {
			state.payed_photos = response
		},
		setPayedPhotosIds(state, response) {
			state.payed_photos_ids = response
		},
		setPhotosByAthletes(state, response) {
			state.photos_by_athletes = response
		},
		addPhotos(state, response) {
			state.photobank = state.photobank.concat(response.items)
			state.page++
		},
		setCartPhotos(state, response) {
			state.cart_photos = response
		},
		setAlbums(state, response) {
			state.albums = response
		},
		setAlbum(state, response) {
			state.album = response
		},
		setSearchTips(state, response) {
			state.searchtips = response
		},
		
		
		setPhotobankCategories(state, response) {
			state.photobank_categories = response
		}, */
	},
	actions: {
        changeLocalStorage({state}) {
			localStorage.setItem('shop_cart', JSON.stringify(state.cart_items))
		},
		async fetchCheckout({state, commit}){
            try{
                const response = await api.get(process.env.VUE_APP_BASE_URL+'shop/checkout')
                return response.data
            } catch (e){
                console.log("Ошибка получения данных с сервера о статусе оплаты")
                console.log(e)
                return;
            }
        },
		async fetchPaymentStatus({state, commit}, id){
            try{
                const response = await api.get(process.env.VUE_APP_BASE_URL+'checkPaymentStatus/ShopOrder/'+id)
                return response.data
            } catch (e){
                console.log("Ошибка получения данных с сервера о статусе оплаты")
                console.log(e)
            }
        },
		async getPaymentUrl({state, commit}, order_id){
            try{
                const response = await api.get(process.env.VUE_APP_BASE_URL+'shop/order/'+order_id+'/toPayment', {params: {
					order_id: order_id, 
					order_type: 'ShopOrder',
				}})
                return response.data
            } catch (e){
				console.log(e);
                console.log("Ошибка получения ссылки на оплату с сервера ")
            }
        },
		/* async fetchPhotobankCategories({ state, commit }) {
			if(state.photobank_categories.length > 0) return
			try {
				const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/categories')
				commit('setPhotobankCategories', response.data)
			} catch (e) {
				alert('Ошибка получения данных с сервера о фотобанке')
			}
		},
		async fetchPhotos({ state, commit }, search) {
			try {
				const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/photos', {
					params: {
						page: 1,
						page_size: 20,
						search: search ? search : null
					}
				})
				commit('setPhotos', response.data)
			} catch (e) {
				alert('Ошибка получения данных с сервера о фотобанке')
			}
		},
		async reFetchPhotos({ state, commit }) {
			try {
				const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/photos', {
					params: {
						page: state.photobank.page + 1,
						page_size: 20,
						search: state.search ? state.search : null
					}
				})
				commit('addPhotos', response.data)
			} catch (e) {
				alert('Ошибка получения данных с сервера о фотобанке')
			}
		},
		async fetchAlbums({ state, commit }) {
			try {
				const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/albums')
				commit('setAlbums', response.data)
			} catch (e) {
				console.log(e);
				alert('Ошибка получения данных с сервера о альбомах фотобанка')
			}
		},

		async fetchAlbum({ state, commit }, id) {
			try {
				const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/albums/' + id)
				commit('setAlbum', response.data)
			} catch (e) {
				alert('Ошибка получения данных с сервера о альбоме')
			}
		},

		async fetchSearchTips({ state, commit }) {
			if(_.isEmpty(state.searchtips)){
				try {
					const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/searchtips')
					commit('setSearchTips', response.data)
				} catch (e) {
					alert('Ошибка получения данных с сервера о поисковых подсказках')
				}
			}
		},

		async fetchCartPhotos({ state, commit }, ids) {
			if(!ids) ids = state.cart
			let exist = state.cart_photos.filter(el => ids.includes(el.id))
			if(exist.length === state.cart.length) return
			try {
				const response = await axios.get(process.env.VUE_APP_BASE_URL + 'photobank/photosbyids', {
					params: {
						ids: ids
					}
				})
				commit('setCartPhotos', response.data)
			} catch (e) {
				console.log(e)
				alert('Ошибка получения данных с сервера о альбоме')
			}
		},

		async fetchPayedPhotos({ state, commit }, order_id){
            api.get(process.env.VUE_APP_BASE_URL+'getpayedphotos', {
				params: {
					order_id: order_id ? order_id : null
				}
			}).then(response => {
				commit('setPayedPhotos', response.data)
            }).catch(error => {
                console.log(error)
                alert('Ошибка получения данных с сервера о купленных фото')
            })
        },

		async fetchPayedPhotosIds({ state, commit }, force){
            api.get(process.env.VUE_APP_BASE_URL+'getpayedphotosids').then(response => {
				commit('setPayedPhotosIds', response.data)
            }).catch(error => {
                console.log(error)
                alert('Ошибка получения данных с сервера о id купленных фото')
            })
        },

		async fetchPhotosByAthletes({ state, commit }){
            api.get(process.env.VUE_APP_BASE_URL+'getphotosbyathletes').then(response => {
				commit('setPhotosByAthletes', response.data)
            }).catch(error => {
                console.log(error)
                alert('Ошибка получения данных с сервера о добавленных атлетах')
            })
        }, */
	},
	namespaced: true,
}
