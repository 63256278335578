<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2">Выберите дополнительные услуги</h2>
    </div>
    <div class="upsells_form">
        <template v-for="(item, i) in items" :key="i" >
        <UpsellItem 
            v-model="theModel[i]" 
            :item="item" 
            :distances="distances"
        />
        </template>
    </div>
    <div v-if="summ" class="upsells__summ text-end mt-4 fw-bold text_20">
        <span>Итого: </span>
        <span class="text-success">{{summ}} ₽</span>
    </div>
</div>
</template>

<script>
import UpsellItem from '../../components/forms/UpsellItem.vue'
import { computed } from 'vue';
export default {
    props: {
        items:{
            type: Array,
            default: []
        },
        modelValue:{
            type: Array,
            default: () => ({}),
        },
        distances:{
            type: Array,
            default: []
        },
    },
    async mounted(){
        this.setExtraServices()
    },
    methods: {
        setExtraServices(){
            let saveModel = this.theModel
            let newModel = []
            this.items.forEach(item => {

                let exist_service = saveModel.find(exist_item => item.id == exist_item.id)             
                if(exist_service){
                    newModel.push(exist_service)
                    return
                }

                if(item.type == 'for_distances' || item.type == 'for_distances_length'){
                    newModel.push({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        distances: [],
                        quantity: 0,
                    })
                } else if(item.type == 'for_athletes') {
                    newModel.push({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        distances: [],
                        quantity: 0,
                    })
                } else if(item.type == 'default') {
                    newModel.push({
                        id: item.id,
                        quantity: 0,
                        name: item.name,
                        price: item.price,
                    })
                }
            })
            this.theModel = newModel
        },
    },
    setup(props, { emit }) {
        const theModel = computed({  
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });
        return {theModel};
    },
    computed: {
        summ(){
            let summ = 0
            this.theModel.forEach(item => {
                summ = summ + (item.quantity * item.price)
            })
            return summ
        }
    },
    emits: ['update:modelValue'],
    components: { UpsellItem}
}
</script>

<style>

</style>
