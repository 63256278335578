<template>
<OffcanvasInline @show="showModal" @hide="$emit('hide'), visible = !visible" :visible="visible">
<p class="section_title_medium mb-3">{{item.name}}</p>
<p class="text-muted mb-3">{{item.description}}</p>

<p class="fw-bold mb-1">Отметьте спортсменов и дистанции, для которых нужно получить услугу:</p>

<div class="athletes_checkboxes mb-3">
    <template v-for="(elem, i) in distances" :key="i">
        <div v-if="elem.athlete" class="athlete_checkboxes__item mb-2">
            <div class="athlete_checkboxes__header d-flex justify-content-between align-items-center p-3">
            <div class="table_name_item d-flex align-items-center">
                <Avatar :name="elem.athlete.name" :src="elem.athlete.avatar" :class="'me-2'" />
                <div class="name">
                    <span class="fw-bold">{{elem.athlete.name}}</span>
                    <span class="d-block text-muted small">{{elem.athlete.birthdate}} г.р</span>
                </div>
            </div>
            <input 
                :checked="isAthleteChecked(elem.athlete.id)"
                @change="checkAthlete($event, elem.athlete.id)" type="checkbox" class="form-check-input ml-2">
            </div>

            <transition name="slide">
                <div v-if="isAthleteChecked(elem.athlete.id)" class="athlete_checkboxes__distancies px-3">
                    <template v-for="(distance, index) in elem.distances" :key="index">
                        <!-- <div v-if="isAllowed(distance)" class="athlete_checkboxes__distancies__item d-flex justify-content-between py-3">
                            <span class="fw-bold" :class="isDistanceChecked(elem.athlete.id, distance.id) ? 'text-success' : ''">{{distance.name}}</span>
                            <input @change="checkDistance($event, elem.athlete.id, distance.id, distance.distance)" type="checkbox" class="form-check-input ml-2">
                        </div> -->
                        <UpsellDistanceCheckbox 
                            :distance="distance" 
                            :service="item" 
                            :checked="isDistanceChecked(elem.athlete.id, distance.id)" 
                            @checkDistance="checkDistance($event, elem.athlete.id, distance.id, distance.distance)" 
                            @uncheckDistance="uncheckDistance(elem.athlete.id, distance.id, distance.distance)" />
                    </template>
                </div>
            </transition>
        </div>
    </template>

</div>
<!-- toggleOffcanvas('upsell_for_every') -->
<button @click.prevent="save" class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">Сохранить</button>
</OffcanvasInline>
</template>

<script>
import UpsellDistanceCheckbox from '../forms/UpsellDistanceCheckbox'
import OffcanvasInline from './OffcanvasInline.vue'
import Avatar from '../UI/Avatar'
import { computed } from 'vue';
export default {
    props: {
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        visible: {
            type: Boolean
        },
        item: {
            type: Object,
            default: {}
        },
        distances: {
            type: Array,
            default: []
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const theModel = computed({  
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });
        return { theModel };
    },
    methods: {
        isAthleteChecked(id){
            if(_.isEmpty(this.theModel.distances)) return
            let exist = this.theModel.distances.find(item => {
                return item.athlete_id == id
            })
            if(exist) return true
            return
        },
        showModal(){
        },
        checkAthlete(e, id){
            if(e.target.checked === true){
                this.addModelItem(id)
            } else {
                this.removeModelItem(id)
            }
        },
        addModelItem(athlete_id){
            this.theModel.distances.push({
                athlete_id: athlete_id,
                distances: []
            })
        },
        removeModelItem(id){
            if(_.isEmpty(this.theModel.distances)) return
            let index = this.theModel.distances.findIndex((item) => {
                return item.athlete_id == id
            })
            if(index !== -1){
                this.theModel.distances.splice(index, 1)
            }
        },
        isDistanceChecked(athlete_id, id){
            if(_.isEmpty(this.theModel.distances)) return
            let index = this.theModel.distances.findIndex(item => {
                return item.athlete_id == athlete_id
            })
            if(index !== -1){
                if(this.theModel.distances[index].distances.includes(id)) return true
            }
            return
        },
        checkDistance(e, athlete_id, distance_id, distance_distance){
            if(_.isEmpty(this.theModel.distances)) return
            let index = this.theModel.distances.findIndex(item => {
                return item.athlete_id == athlete_id
            })
            if(index !== -1){
                if(e.target.checked === true){
                    this.theModel.distances[index].distances.push(distance_id)
                    if(this.item.type == 'for_distances_length'){
                        this.theModel.quantity = this.theModel.quantity + 1 * distance_distance
                    }
                    else {
                        this.theModel.quantity = this.theModel.quantity + 1
                    }
                } else {
                    this.uncheckDistance(athlete_id, distance_id, distance_distance)
                    /* let dist_i = this.theModel.distances[index].distances.indexOf(distance_id);
                    if (dist_i !== -1) {
                        this.theModel.distances[index].distances.splice(dist_i, 1);
                        this.theModel.quantity = this.theModel.quantity - 1
                    } */
                }
            }
        },
        uncheckDistance(athlete_id, distance_id, distance_distance){
            let index = this.theModel.distances.findIndex(item => {
                return item.athlete_id == athlete_id
            })

            let dist_i = this.theModel.distances[index].distances.indexOf(distance_id);

            if (dist_i !== -1) {
                this.theModel.distances[index].distances.splice(dist_i, 1);
                this.theModel.quantity = this.theModel.quantity - 1
            }
        },
        save(){
            if(this.theModel.distances.length > 0){
                let index = this.theModel.distances.findIndex(item => {
                    return _.isEmpty(item.distances)
                })
                if(index !== -1){
                    this.theModel.distances.splice(index, 1)
                }
            }
            this.$emit('hide')
        }
    },
    components: {
    UpsellDistanceCheckbox,
        Avatar, OffcanvasInline
    },
}
</script>

<style scoped>
    .athlete_checkboxes__header{
        background: #e9ebf1;
        border-radius: 5px;
    }

    .athlete_checkboxes__distancies{
        background: #f5f6fa;
        padding-top: 5px;
        margin-top: -5px;
    }

    .athlete_checkboxes__distancies__item{
        border-bottom: 1px solid #E9EBF1;
    }

    .athlete_checkboxes__distancies__item:last-child{
        border: none;
    }

    .athlete_checkboxes__header .form-check-input:checked{
        background-color: #071031;
        border-color: #071031;
    }

    .athlete_checkboxes__distancies .form-check-input:checked{
        background-color: #28C76F;
        border-color: #28C76F;
    }

    .slide-enter-active,
    .slide-leave-active {
    transition: max-height 0.2s ease-in-out;
    }

    .slide-enter-to,
    .slide-leave-from {
    overflow: hidden;
    max-height: 1000px;
    }

    .slide-enter-from,
    .slide-leave-to {
    overflow: hidden;
    max-height: 0;
    }
</style>
