<template>
	<div class="inputgroup_athlet content_box p-4 mb-4">
		<button
			@click.prevent="$emit('remove', index)"
			v-if="delete_btn"
			class="btn btn-circle text-muted mb-2 ms-auto">
			<vue-feather type="trash-2" />
		</button>
		<div class="row g-3">
			<AthleteSelect
				v-model="theModel.athlete_id"
				:athlete="theModel.athlete"
				:used_athletes="used_athletes"
				:disallowed_athletes="disallowed_athletes"
				:age_limits="age_limits"
				:show_errors="show_errors"
				@selectAthlete="selectAthlete" />

			<div v-if="theModel.athlete_id && this.required_documents" class="col-sm-6 col-xxl-3">
				<div class="form-check mt-4">
					<input
						v-model="theModel.online_request"
						@change="changeOnlineRequest"
						class="form-check-input"
						:id="'onlide_docs_' + index"
						type="checkbox"
						value="1" />
					<label class="form-check-label small" :for="'onlide_docs_' + index">
						Предоставить документы для онлайн-допуска
					</label>
				</div>
			</div>

			<div
				v-if="theModel.athlete_id"
				class="col-sm-6 col-xxl-3 d-flex justify-content-sm-end">
				<div v-if="theModel.online_request" class="docs_checker">
					<p class="fw-bold mb-1">Документы</p>
					<div class="docs_checker__cont d-flex">
						<template v-if="!isDocumentsFull()">
							<div class="alert alert-xs alert-danger bg-body mb-0 me-2">
								<span class="alert-title">Недостаточно</span>
							</div>
							<button
								@click.prevent="visible_modals.edit_documents = true"
								class="btn btn-sm btn-primary btn-primary-shadow">
								Добавить
							</button>
						</template>
						<template v-else>
							<div class="alert alert-xs alert-success bg-body mb-0 me-2">
								<span class="alert-title">Добавлены</span>
							</div>
							<button
								@click.prevent="visible_modals.edit_documents = true"
								class="btn btn-sm btn-primary btn-primary-shadow">
								Посмотреть
							</button>
						</template>
					</div>
					<p
						v-if="show_errors && theModel.online_request && !isDocumentsFull()"
						class="mb-0 small text-danger">
						Необходимо добавить документы
					</p>
				</div>
				<AddDocuments
					:visible="visible_modals.edit_documents"
					:documents_data="documentOffcanvasData()"
					@changeData="changeDocuments"
					@hide="visible_modals.edit_documents = false" />
			</div>

			<div
				v-if="theModel.athlete_id && !theModel.online_request && offline_request_text"
				class="col-12">
				<div
					v-html="offline_request_text"
					class="alert alert-warning bg-body mb-0 me-2"></div>
			</div>

			<template v-if="theModel.athlete_id && allowed_distances">
				<AthleteDistance
					v-for="(distance, i) in theModel.distances"
					:key="distance.key"
					v-model="theModel.distances[i]"
					:index="distance.key"
					:delete_btn="theModel.distances.length > 1 ? true : false"
					:allowedDistances="allowedDistances"
					:used_distances="used_distances"
					:show_errors="show_errors"
					@changeDistance="changeDistance"
					@removeDistance="removeDistance" />
			</template>
			<div v-if="theModel.athlete_id && allowed_distances" class="col-12">
				<button
					@click.prevent="addDistance(theModel.distances.length)"
					class="btn btn-warning btn-sm">
					Добавить дистанцию
				</button>
			</div>
		</div>

		<div v-if="theModel.loading" class="loading_overlay">
			<CSpinner color="primary" />
		</div>
	</div>
</template>

<script>
import AthleteDistance from './AthleteDistance'
import AthleteSelect from './AthleteSelect'
import AddDocuments from '../offcanvases/AddDocuments.vue'
import { CSpinner } from '@coreui/vue'
import { computed } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { checkAgeLimit } from '@/API/api'
import moment from 'moment'

export default {
	props: {
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
		},
		required_documents: {
			type: Array,
			default: [],
		},
		allowed_distances: {
			type: Array,
			default: [],
		},
		athletes: {
			type: Array,
			default: [],
		},
		allAthletes: {
			type: Array,
			default: [],
		},
		event_stage_id: {
			type: Number,
		},
		offline_request_text: {
			type: String,
		},
		age_limits: {
			type: Array,
			default: [],
		},
		agedata_value: {
			type: String,
			default: null,
		},
		used_athletes: {
			type: Array,
			default: [],
		},
		disallowed_athletes: {
			type: Array,
			default: [],
		},
		delete_btn: {
			type: Boolean,
			default: false,
		},
		has_errors: {
			type: Boolean,
			default: () => false,
		},
		show_errors: {
			type: Boolean,
			default: () => false,
		},
	},
	data() {
		return {
			used_distances: [],
			distance_metadata: {
				selected_athlete: null,
				selected_distances: [],
			},
			visible_modals: {
				edit_athlete: false,
				edit_documents: false,
			},
		}
	},
	watch: {
		theModel: {
			handler(newValue, oldValue) {
				this.validateFields()
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			getDistancePlaces: 'events/getDistancePlaces',
		}),
		allowedDistances() {
			if(this.theModel.athlete && this.theModel.athlete.birthdate){

				let birthdate = moment(this.theModel.athlete.birthdate, 'DD/MM/YYYY')

                let years = moment().endOf('year').diff(birthdate, 'years')
                if(this.agedata_value){
                    years = moment(this.agedata_value).diff(birthdate, 'years')
                }
				
				return this.allowed_distances.filter((item) => {
					if (
						item.agemin <= years &&
						item.agemax >= years &&
						this.theModel.athlete.gender == item.gender
					)
						return true
				})
			}
			
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		const has_errors = computed({
			get: () => props.has_errors,
			set: (value) => emit('update:has_errors', value),
		})
		return { theModel, has_errors }
	},
	mounted() {
		this.validateFields()
	},
	beforeUpdate() {
		this.validateFields()
	},
	methods: {
		selectAthlete(athlete) {
            
			if (!athlete) {
				this.clearData()
				return
			}
			this.checkAthleteLimit(athlete)
			this.used_athletes[this.index] = athlete.id
			this.setTheModelData(athlete)
		},
		async checkAthleteLimit(athlete) {
			this.theModel.loading = true
			let data = {
				event_stage_id: this.event_stage_id,
				gender: athlete.gender,
				birthyear: athlete.birthdate.split('/')[2],
			}
			let response = await checkAgeLimit(data)
			if (!response) {
				this.used_athletes.push(athlete.id)
				this.clearData()
				this.$swal(
					'Превышен лимит участников в этой возрастной группе',
					'К сожалению, больше нельзя подавать заявки в этой возрастной группе',
					'error'
				)
			}
			this.theModel.loading = false
		},
		setTheModelData(item) {
			this.theModel.athlete = item
			this.theModel.online_request = false
			this.theModel.athlete_documents = []
			if (this.allowed_distances) {
				this.theModel.distances = [
					{
						entrytime: null,
						id: null,
						key: 0,
					},
				]
			}
		},
		clearData() {
			this.theModel.athlete = null
			this.theModel.athlete_id = null
			this.theModel.online_request = false
			this.theModel.athlete_documents = []
			if (this.allowed_distances) {
				this.theModel.distances = [
					{
						entrytime: null,
						id: null,
						key: 0,
					},
				]
			}
		},
		changeOnlineRequest() {
			if (
				this.theModel.online_request &&
				this.required_documents &&
				this.required_documents.length > 0
			) {
				if(!this.theModel.athlete_documents){
					this.theModel.athlete_documents = [];
				}
				this.required_documents.forEach((name) => {
					let athlete_doc = this.theModel.athlete.documents.find((item) => {
						return item.name === name
					})
					if (athlete_doc) {
						this.theModel.athlete_documents.push({
							id: athlete_doc.id ? athlete_doc.id : null,
							name: name,
							document: athlete_doc.document,
						})
					} else {
						this.theModel.athlete_documents.push({ 
							id: null,
							name: name, 
							document: null 
						})
					}
					
				})
			} else {
				this.theModel.athlete_documents = []
			}
			//this.checkDocumentsFull()
		},
		setDocumentToFormData(){
			this.required_documents.forEach((name) => {
				let athlete_doc = this.theModel.athlete.documents.find((item) => {
					return item.name === name
				})
				if (athlete_doc) {
					let formdata_doc_i = this.theModel.athlete_documents.findIndex(item => item.name == athlete_doc.name)
					if(formdata_doc_i != -1){
						this.theModel.athlete_documents[formdata_doc_i] = {
							id: athlete_doc.id ? athlete_doc.id : null,
							name: athlete_doc.name,
							document: athlete_doc.document,
						}
					}
				}
			})
		},
		changeDocuments(athlete) {
			let check = false
			
			this.athletes.forEach((item) => {
				if (item.id === athlete.id) {
					item.documents = athlete.documents
                    console.log(item.documents);
					check = true
				}
			})
			if (!check) {
				this.allAthletes.forEach((item) => {
					if (item.id === athlete.id) {
						item.documents = athlete.documents
						check = true
					}
				})
			}
			this.setDocumentToFormData()
		},
		addDistance(key) {
			this.theModel.distances.push({
				name: null,
				key: key,
				id: null,
				entrytime: null,
				distance: null,
				price: 0,
                count: 'one',
                is_payed: 0,
			})
		},
		removeDistance(index) {
			this.theModel.distances.splice(index, 1)
			this.used_distances.splice(index, 1)
		},
		async changeDistance(i) {
			this.theModel.loading = true
			let distance_id = this.theModel.distances[i].id
			await this.fetchDistancePlaces(distance_id)
			let free_places = this.getDistancePlaces(distance_id)
			if (free_places > 0) {
				this.used_distances[i] = this.theModel.distances[i].id
				this.theModel.distances[i].entrytime = null
				this.theModel.distances[i].system_entrytime = false

				let distance = this.allowedDistances.find((item) => {
					return item.id == distance_id
				})

				if(distance.distance){
					this.theModel.distances[i].distance = distance.distance
				}
				
				this.theModel.distances[
					i
				].name = `${distance.distance}м, ${distance.stroke_name}, ${distance.age_name}`
				let entrytimes = this.theModel.athlete.results.filter((item) => {
					if (
						item.stroke == distance.stroke &&
						item.distance == distance.distance &&
						item.gender == distance.gender
					) {
						return true
					}
				})
				if (entrytimes.length > 0) {
					
					if (entrytimes.length == 1) {
						this.theModel.distances[i].entrytime = entrytimes[0].short_swimtime
						this.theModel.distances[i].system_entrytime = true
					} else {
						this.theModel.distances[i].entrytime = entrytimes.sort((a, b) =>
							 moment(a.short_swimtime, 'mm:ss.SS') - moment(b.short_swimtime, 'mm:ss.SS')
					)[0].short_swimtime
						this.theModel.distances[i].system_entrytime = true
					}
				}
				this.$emit('addDistance', distance_id)
			} else {
				this.setDistanceDisabled(distance_id)
				this.theModel.distances[i] = {
					name: null,
					key: this.theModel.distances[i].key,
					id: null,
					entrytime: null,
					distance: null,
					price: 0,
					count: 'one',
                    is_payed: 0,
				}
				this.$swal('К сожалению, в этой дистанции закончились места', '', 'warning')
			}
            this.theModel.loading = false
		},
		isDocumentsFull() {
			if(!this.theModel.athlete_documents) return false
			if(typeof this.theModel.athlete_documents == 'string') return true
			let exist = !this.theModel.athlete_documents.find((item) => {
				return item.document === null
			})
			if (exist) return true
			return false
		},
		documentOffcanvasData() {
			return {
				id: this.theModel.athlete_id,
				documents: this.theModel.athlete_documents,
				required_documents: this.required_documents,
			}
		},
		validateFields() {
			if (
				!this.theModel.athlete_id ||
				(this.theModel.online_request && !this.isDocumentsFull()) ||
				!this.validateDistances()
			) {
				this.has_errors = true
			} else {
				this.has_errors = false
			}
		},
		validateDistances() {
			let res = true
			if (this.allowed_distances) {
				this.theModel.distances.forEach((item) => {
					if (!item.id || !item.entrytime) res = false
				})
			}
			return res
		},
		...mapActions({
			fetchDistancePlaces: 'events/fetchDistancePlaces',
		}),
		...mapMutations({
			setDistanceDisabled: 'events/setDistanceDisabled',
		}),
	},
	emits: ['update:modelValue', 'update:has_errors', 'addDistance'],
	components: {
		AthleteDistance,
		AthleteSelect,
		AddDocuments,
		CSpinner,
	},
}
</script>

<style scoped>
.inputgroup_athlet {
	position: relative;
}

.loading_overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>