<template>
    <span v-if="status === 'not_completed'" class="badge bg-info">В работе</span>
    <span v-if="status === 'completed'" class="badge bg-success">Выполнен</span>
    <span v-if="status === 'rejected'" class="badge bg-danger text-white">Отменен</span>
</template>

<script>
export default {
    props: ['status'],
}
</script>

<style>

</style>