<template>
<OffcanvasInline id="add_documents" @show="showModal" @hide="$emit('hide'), visible = !visible" :visible="visible">
<p class="section_title_medium mb-4">Добавить документы</p>
<div class="mb-4">
<div class="mb-2">
    <UploadDocuments
        :name="form_data.name" 
        :birthdate="form_data.birthdate" 
        :avatar="form_data.avatar"
        :required_documents="form_data.required_documents" 
        :is_active="true"
        v-model="form_data.documents"
    />
</div>
</div>

<button @click.prevent="postDocuments" class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">Сохранить</button>

</OffcanvasInline>
</template>

<script>
import OffcanvasInline from './OffcanvasInline.vue'
import UploadDocuments from '../forms/UploadDocuments'
import UploadAvatar from '../forms/UploadAvatar.vue'
import axios from "axios"
export default {
    components: {
        UploadDocuments,
        UploadAvatar, OffcanvasInline
    },
    data(){
        return {
            form_data: {
                id: null,
                documents: []
            },
            error_message: null
        }
    },
    methods: {
        /* addFile(data){
            if(this.form_data.documents.length > 0 && this.form_data.documents.find(item => item.name === data.name)){
                this.form_data.documents.map(obj => data.find(o => o.name === obj.name) || obj)
            } else {
                this.form_data.documents.push(data)
            }
        },
        removeFile(data){
            var index = this.form_data.documents.findIndex(object => {
                return object.name === data.name;
            });
            if (index !== -1) {
                this.form_data.documents.splice(index, 1);
            }
        }, */
        async postDocuments(){
            await axios.post(process.env.VUE_APP_BASE_URL+'athlete/documents/'+this.form_data.id+'/edit', this.getFormData() ,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            }).then(res => {
                if(res.data && res.data?.success){                  
                    this.$swal('Изменения сохранены', '', 'success')
                    this.$emit('changeData', res.data?.athlete_data)
                    this.$emit('hide')
                } else {
                    this.$swal('Неизвестная ошибка, попробуйте еще раз', '', 'error')
                }
            }).catch(error => {
                console.log(error)
                this.error_message = error.response.data.message
                this.$swal(error.response.data.message, '', 'error')
            })
        },
        getFormData(){
            let fd = new FormData();
            if(this.form_data.documents){
                for(var i = 0; i < this.form_data.documents.length; i++){
                    if(this.form_data.documents[i].document && typeof this.form_data.documents[i].document == 'object'){
                        fd.append('documents['+i+'][name]', this.form_data.documents[i].name)
                        for (let n = 0; n < this.form_data.documents[i].document.length; n++) {
                            let file = this.form_data.documents[i].document.item(n);
                            fd.append('documents['+i+'][document][]', file)
                        }
                    }
                }
            }
            return fd
        },
        showModal(){
            if(this.documents_data){
                this.form_data = Object.assign(this.form_data, this.documents_data)
            }
        },
        /* hideModal(){
            this.form_data = {
                id: null,
                documents: []
            }
        } */
    },
    props: ['visible', 'documents_data'],
    emits: ['changeData', 'hide']
}
</script>

<style>
</style>
