<template>
	<div class="card event_card">
		<img :src="event.thumbnail" class="card__img" />
		<div class="card__overlay"></div>
		<div v-if="event.dates" class="card__date small">
			<vue-feather type="calendar"></vue-feather> {{ event.dates }}
		</div>
		<div class="card__meta">
			<p v-if="event.card_prefix" class="event_card__prefix mb-0">
				{{ event.card_prefix }}
			</p>
			<router-link
				v-if="event.type === 'competition'"
				:to="'/stage/' + event.slug"
				class="event_card__title card_title_large d-block text-decoration-none mb-2"
				>{{ event.name }}</router-link
			>
			<router-link
				v-else
				:to="'/events/' + event.slug"
				class="event_card__title card_title_large d-block text-decoration-none mb-2"
				>{{ event.name }}</router-link
			>
			<div v-if="event.city" class="event_card__location text_bold_20">
				<vue-feather type="map-pin" class="me-1"></vue-feather>{{ event.city }}
			</div>
			<router-link
				v-if="event.type === 'competition' && isRegistrationTime"
				:to="{ name: 'event_form', query: { id: event.id, type: 'competition' } }"
				class="btn btn-rounded btn-warning button_16 mt-3">
				Подать заявку
			</router-link>
			<router-link
				v-if="event.type === 'camp' && event.has_active_shifts"
				:to="'/events/' + event.id + '/#camps'"
				class="btn btn-rounded btn-warning button_16 mt-3"
				>Выбрать смену</router-link
			>
			<router-link
				v-if="event.type === 'master_class'"
				:to="{ name: 'event_form', query: { id: event.id, type: 'master_class' } }"
				class="btn btn-rounded btn-warning button_16 mt-3"
				>Подать заявку</router-link
			>
		</div>
		<div class="card__meta__overlay" :style="cardOverlay"></div>
	</div>
</template>

<script>
import moment from 'moment-timezone';

export default {
	props: ['event'],
	computed: {
		isRegistrationTime() {
			if (this.event.type !== 'competition') return true
			if (!this.event.start_registration_date || !this.event.end_registration_date)
				return false
			let now = moment(this.event.current_date, 'DD-MM-YYYY HH:mm')
			let start = moment(this.event.start_registration_date, 'DD-MM-YYYY HH:mm');
			let end = moment(this.event.end_registration_date, 'DD-MM-YYYY HH:mm');
			
			if (start < now && now < end) return true
			return
		},
        cardOverlay(){
            let color = '#030C2F';
            if(this.event.color) color = this.event.color
            return 'background: linear-gradient(to top, '+ color +', transparent);'
        }
	},
}
</script>

<style scoped>
a.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.event_card{
    min-height: 100%;
}
</style>