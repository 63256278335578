import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFeather from 'vue-feather';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import _ from 'lodash';
import Maska from 'maska'
import { createHead } from '@vueuse/head'
import eventBus from 'vue3-eventbus'
import VueYandexMetrika from 'vue3-yandex-metrika' 

const toastOptions = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
};

const swalOptions = {
    confirmButtonColor: '#0d6efd',
    cancelButtonColor: '#B9B9C3',
    customClass: {
        confirmButton: 'btn fw-bold',
        denyButton: 'btn fw-bold',
        cancelButton: 'btn fw-bold',
    }
};

const head = createHead()
const app = createApp(App)

/* store.subscribe((mutation, state) => {
	console.log(mutation.type)
	console.log(mutation.payload)
}) */

app.use(store)
app.use(router)
app.use(_)
app.use(Maska)
app.use(Toast, toastOptions)
app.use(VueSweetalert2, swalOptions);
app.use(head)
app.use(eventBus)
app.component(VueFeather.name, VueFeather)
app.use(VueYandexMetrika, {
	id: 94935263,
	router: router,
	env: process.env.NODE_ENV
})
app.mount('#app')

